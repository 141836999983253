import React from "react"
import { Link, graphql } from "gatsby"
import { Container } from 'reactstrap'
import Img from "gatsby-image"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Index({ data }) {
  const { edges: products } = data.allMarkdownRemark
  const { coverImage } = data
  // console.log('index.js data', data)

  return (
    <Layout headerImage={coverImage.childImageSharp.fluid}>
      <SEO title="Cellphone Cases" keywords={[`Tangerine Fix`, `cell phone case`]} />
      <Container>
        <p class="lead">Protective and elegant phone cases</p>

          <div class="row">
            {products
              .map(({ node: product }) => {
                console.log('product', product)
                return (
                  <div class="col">
                    <Link
                      to={product.fields.slug}
                      className="card-link"
                      css={css`
                        text-decoration: none;
                        color: inherit;
                        &:hover {
                          text-decoration: none;
                          color: inherit;
                        }
                      `}
                    >
                      <div className="card bg-dark text-white m-1" css={css`
                        border: 1em;
                        width: 15rem;
                        height: 8rem;
                      `}>
                      {product.frontmatter.featuredImage && 
                        <Img class="card-img" css={css`
                          opacity: 0.3;
                        `}
                          fluid={product.frontmatter.featuredImage.childImageSharp.fluid}/>
                      }
                      <div class="card-img-overlay">
                        <h5 class="card-title">{product.frontmatter.title}</h5>
                        <p class="card-text">{product.frontmatter.summary}</p>
                      </div>
                    </div>
                  </Link>
                </div>
                )
              })
            }
          </div>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ProductCellphoneCasePageQuery {
    coverImage: file(
      relativePath: { regex: "/IMG_2350c/" }
    ) {
      childImageSharp {
        fluid(
          maxWidth: 1024
          quality: 80
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fields: { collection: { eq: "phone-case" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 412) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
 `